import { useMemo } from "react"
import { motion, transform, useMotionValue } from "framer-motion"
import styles from "./styles.module.css"

function roundToNearest(value, interval) {
  return Math.round(value / interval) * interval;
}

export default function Knob({ value, onChange, children, min, max, step, acceleration = 1, ...props }) {
  const rotation = useMotionValue(value)
  const transformer = useMemo(() => transform([min, max], ["-0.35", "0.35"]), [min, max])
  const rotate = transformer(value)

  const handleMouseDown = down => {
    const handleMouseMove = move => {
      const change = (down.clientY - move.clientY) / acceleration
      const nextValue = roundToNearest(value + change, step)
      if (nextValue >= min && nextValue <= max) {
        onChange(nextValue)
        rotation.set(nextValue)
      }
    }

    const handleMouseUp = () => {
      window.removeEventListener("mouseup", handleMouseUp)
      window.removeEventListener("mousemove", handleMouseMove)
    }

    window.addEventListener("mouseup", handleMouseUp)
    window.addEventListener("mousemove", handleMouseMove)
  }

  const ticks = useMemo(() => {
    let output = []
    for (let i = min; i <= max; i += step) {
      const rotation = transformer(i);
      output.push(<div key={i} className={styles.knobTick} style={{ "--color": i <= value ? "#EB981C" : "#6D6973", transform: `rotate(${rotation}turn)` }} />)
    }
    return output
  }, [min, max, step, value])


  return (
    <div className={styles.knob}>
      <input type="range" value={value} readOnly min={min} max={max} step={step} {...props} />
      <div className={styles.knobSocket}>
        <div className={styles.knobControl} onMouseDown={handleMouseDown}>
          {ticks}
          <motion.div className={styles.knobRotation} style={{ transform: `rotate(${rotate}turn)` }} />
        </div>
      </div>
      <span className={styles.label}>
        {children}
      </span>
    </div>
  )
}

