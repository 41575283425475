import { useEffect, useMemo } from "react"
import styles from "./styles.module.css"
import Pusher from 'pusher-js';

function usePusher() {
  useEffect(() => {
    Pusher.logToConsole = true;
    const pusher = new Pusher('a86359a9c3bfc40ee5dc', {
      cluster: 'eu'
    })

    var channel = pusher.subscribe('my-channel');
    channel.bind('my-event', function(data) {
      console.log(JSON.stringify(data));
    });

    return () => {
      channel.unbind_all();
    }
  }, [])
}

function Server() {
  usePusher();
  return <>Server is listening...</>
}

function Split({ children }) {
  return (
    <div className={styles.split}>
      {children}
    </div>
  )
}

export default function LocalServer() {
  return (
    <div className={styles.page}>
      <Split>
        <Server />
      </Split>
      <Split>
        Interactive stuff
        <button>Send request</button>
      </Split>
    </div>
  )
}
