import * as Popover from '@radix-ui/react-popover';
import { AnimatePresence, motion } from "framer-motion"
import React, { useEffect, useRef, useState } from 'react';
import Credit from '../../components/Credit';
import css from "./styles.module.css"

function labelForKey(key) {
  if (key === " ") return "␣"
  if (key === "shift") return "⇧"
  if (key === "alt") return "⎇"
  if (key === "meta") return "⌘"
  if (key === "control") return "^"
  if (key === "escape") return "ESC"
  return key
}

function useShortcuts(cb) {
  const timer = useRef(null);
  const keysRef = useRef([])
  const [keys, setKeys] = useState([])

  useEffect(() => {
    keysRef.current = keys

    if (keys.length === 1) {
      timer.current = setTimeout(() => {
        cb(keysRef.current)
      }, 1500)
    }

    const handleKeyDown = e => {
      const lower = e.key.toLowerCase();
      if (keys.includes(lower)) return;

      setKeys(existing => {
        return [...existing, lower]
      })
    }

    const handleKeyUp = e => {
      setKeys([])
      clearTimeout(timer.current)
    }

    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [keys])

  return keys
}

function RecordButton({ onRecord, children, hasShortcut }) {
  const [open, setOpen] = useState(false);

  const handleRecord = keys => {
    setOpen(false)
    onRecord(keys)
  }

  const variants = {
    initial: { opacity: 0, y: 20, scale: 0.8 },
    animate: { opacity: 1, y: 0, scale: 1 },
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger data-has-shortcut={hasShortcut} className={css.record}>{children}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content asChild side="top" sideOffset={10}>
          <motion.div className={css.popover} initial="initial" animate="animate" variants={variants} transition={{ duration: 0.2 }}>
            <RecordShortcut onRecord={handleRecord} />
          </motion.div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

function RecordShortcut({ onRecord }) {
  const shortcut = useShortcuts(onRecord);

  const keyVariants = {
    initial: { x: 15, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: 15, opacity: 0, transition: { duration: 0.3 } },
  }

  const hintVariants = {
    hidden: { opacity: 0, x: 0 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -15, transition: { duration: 0.12 } },
  }

  return (
    <div className={css.shortcut}>
      {shortcut.length > 0 && (
        <svg viewBox="0 0 20 20" className={css.progress}>
          <motion.circle
            cx="10"
            cy="10"
            r="9.5"
            fill="transparent"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: 1 }}
            transition={{ duration: 1.5 }}
          />
        </svg>
      )}
      <AnimatePresence>
        {shortcut.map(s => (
          <motion.kbd className={css.kbd} layoutId={s} initial="initial" animate="animate" exit="exit" key={s} variants={keyVariants}>{labelForKey(s)}</motion.kbd>
        ))}
      </AnimatePresence>
      <AnimatePresence>
        {shortcut.length === 0 && (
          <motion.div layoutId="hint" layout="position" className={css.hint} initial="hidden" animate="visible" exit="exit" variants={hintVariants}>
            <span className={css.shineText}>
              Recording...
            </span>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

function Shortcut({ name }) {
  const [shortcut, setShortcut] = useState([])

  return (
    <div className={css.listItem}>
      <h4>{name}</h4>
      <RecordButton onRecord={setShortcut} hasShortcut={shortcut.length > 0}>
        {shortcut.length > 0 ? (
          shortcut.map(k => <kbd key={k} className={css.kbd}>{labelForKey(k)}</kbd>)
        ) : (
          <span className={css.recordLabel}>Record shortcut</span>
        )}
      </RecordButton>
    </div>
  )
}

export default function KeyboardShortcuts() {
  return (
    <div className={css.page}>
      <div className={css.container}>
        <div className={css.list}>
          <Shortcut name="My tasks" />
          <Shortcut name="Today view" />
          <Shortcut name="Create new issue" />
          <Shortcut name="Planning view" />
          <Shortcut name="Projects" />
        </div>
      </div>
      <Credit />
    </div>
  )
}
