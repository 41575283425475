import glsl from "babel-plugin-glsl/macro";

export default glsl`
  varying vec2 v_uv;

  void main() {
    v_uv = uv;
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;
    gl_Position = projectedPosition;
  }
`;
