import { useSequencer } from "./useSequencer";
import styles from "./styles.module.css"

export default function Leds() {
  const { beat } = useSequencer();

  return (
    <div className={styles.leds}>
      {new Array(16).fill(0).map((_, index) => (
        <div key={index} className={styles.led} data-active={beat === index} />
      ))}
    </div>
  )
}
