import styles from "./styles.module.css"

export default function Dotted() {
  return (
    <div className={styles.page}>
      <div className={styles.light} data-light={1} />
      <div className={styles.light} data-light={2} />
      <div className={styles.light} data-light={3} />
      <div className={styles.light} data-light={4} />
      <div className={styles.wireframe} />
      <div className={styles.card}>
        <div className={styles.cardContents}>
        </div>
      </div>
    </div>
  )
}
