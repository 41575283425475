import glsl from "babel-plugin-glsl/macro";

export const vertex = glsl`
attribute float random;
attribute vec3 shuffled;
attribute float xOffset;
attribute float yOffset;
attribute float delay;
attribute float duration;
attribute vec2 offset;

uniform float uTime;
uniform float uParticleSize;
uniform float uParticleGrowth;
uniform vec3 uShadows;
uniform vec3 uColor;
uniform vec3 uHighlight;
uniform vec2 uResolution;
uniform sampler2D uPictureTexture;
uniform sampler2D uCursorTexture;

varying vec3 vColor;
varying float timeFactor;
varying float intensity;

void main() {
    // get the intesity at the uv of the picture
    // note here UV is not the same as UV from gl_PointCoord
    float textureIntensity = texture(uPictureTexture, uv).r;
    float cursorIntensity = texture(uCursorTexture, uv).r;
    cursorIntensity = smoothstep(0.05, 1.0, cursorIntensity);
    float progress = clamp((uTime - delay) / duration, 0.0, 1.0);
    progress -= cursorIntensity;
    progress = clamp(progress, 0.0, 1.0);
    intensity = mix(random, textureIntensity, progress);


    vec3 pos = position;

    pos.x += mix(offset.x * 0.25, 0.0, progress);
    pos.y += mix(offset.y * 0.25, 0.0, progress);

    float zOffset = mix(0.0, 0.25, textureIntensity);
    pos.z += mix(0.0, zOffset, progress);

    // pos.x = mix(shuffled.x, pos.x, cursorIntensity);
    // pos.y = mix(shuffled.y, pos.y, cursorIntensity);

    // Final position
    vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;

    // Point size
    gl_PointSize = uParticleSize * uResolution.y;
    gl_PointSize += intensity * uParticleGrowth * uResolution.y * progress;
    gl_PointSize *= (1.0 / - viewPosition.z);
    
    // shimmer
    float speed = uTime * 8.0;
    timeFactor = (sin(speed + (random * 100.0)) * 0.5) + 0.5;
    gl_PointSize += (timeFactor * 8.0) * intensity;


    // all white
    // vec3 color = vec3(pictureIntensity)

    vec3 color = mix(uShadows, uColor, intensity);

    vColor = color;
}
`;

export const fragment = glsl`
varying vec3 vColor;
varying float timeFactor;
varying float intensity;

void main() {
    // gl point coordinate gives a UV value of 0 to 1 for each point.
    vec2 uv = gl_PointCoord;
    float distanceToCenter = distance(uv, vec2(0.5));

    // Draw a simple disc
    if (distanceToCenter > 0.5) discard;

    gl_FragColor = vec4(vColor, 1.0);
}
`;
