import glsl from "babel-plugin-glsl/macro";

export default glsl`
  varying vec2 v_uv;
  uniform float u_time;
  uniform float u_speed;
  uniform float u_length;
  uniform vec3 u_color;
  uniform vec3 u_background;

  void main() {
    float start = mod((u_time * u_speed), 1.0);
    float lightLength =u_length;
    float xEnd = start + lightLength;
    float xRemainder = mod(xEnd, 1.0);
    float x = v_uv.x;

    if (x > start && x < xEnd) {
      float strength = (x - start) / lightLength;
      vec3 color = mix(u_background, u_color, strength);
      gl_FragColor = vec4(color, 1.0);
    } else if (xEnd != xRemainder && x < xRemainder) {
      float strength = 1.0 - xRemainder / lightLength;
      strength += ((x / xRemainder) * (1.0 - strength));
       
      vec3 color = mix(u_background, u_color, strength);
      gl_FragColor = vec4(color, 1.0);
    } else {
      discard;
    }
  }
`;
