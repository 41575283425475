import { useState } from "react"
import styles from "./styles.module.css"
import SequencerProvider from "./Provider";
import Grid from "./Grid";
import Leds from "./Leds";
import { useSequencer } from "./useSequencer";
import Knob from "./Knob";

const WIDTH = 600;
const DOT_SIZE = 10;
const BAR_WIDTH = 20;

const NOTES = ["C4", "D4", "E4", "F4", "G4", "A4"]

function Select({ label, value, onChange, width, children }) {
  return (
    <div className={styles.select} style={{ maxWidth: width }}>
      <select value={value} onChange={e => onChange(e.target.value)}>
        {children}
      </select>
      <span className={styles.selectLabel}>{label}:</span> {value}
    </div>
  )
}

const RELEASES = ["1n", "2n", "4n", "8n", "16n", "32n"]

function PlayButton() {
  const { start } = useSequencer();
  return <button onClick={start}>Start</button>
}

export default function Notes() {
  const [delay, setDelay] = useState(0.25)
  const [width, setWidth] = useState(50)
  const [release, setRelease] = useState(5)
  const [scaleKey, setScaleKey] = useState("C")
  const [scale, setScale] = useState("Major")
  const [easing, setEasing] = useState("ease-in-out")

  return (
    <SequencerProvider>
      <div className={styles.page} style={{ "--width": `${WIDTH}px`, "--dot-size": `${DOT_SIZE}px`, "--bar-width": `${BAR_WIDTH}px` }}>
        <div className={styles.container}>
          <div className={styles.topControls}>
            <div className={styles.knobs}>
              <Knob value={delay} onChange={setDelay} acceleration={80} step={0.05} min={0} max={1}>Delay</Knob>
              <Knob value={width} onChange={setWidth} acceleration={5} step={5} min={10} max={50}>Zone</Knob>
              <Knob value={release} onChange={setRelease} acceleration={20} step={1} min={1} max={6}>Release</Knob>
            </div>
            <div className={styles.screen}>
            </div>
          </div>

          <div className={styles.divider} style={{ marginTop: 50, marginBottom: 50 }} />

          <Leds />
          <Grid />
        </div>
        <PlayButton />
      </div>
    </SequencerProvider>
  )
}

