import { AnimatePresence, motion } from "framer-motion"
import { HomeIcon, ArchiveBoxIcon, Cog8ToothIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import css from "./styles.module.css"
import { useState } from "react"
import image from "./avatar.jpg"

const SEARCH = "SEARCH"
const NOTIFICATION = "NOTIFICATION"
const NAVIGATION = "NAVIGATION"
const HOME = "HOME"
const ARCHIVE = "ARCHIVE"
const SETTINGS = "SETTINGS"

function NavLink({ children, isActive, onClick }) {
  return (
    <div data-active={isActive} className={css.navLink} onClick={onClick}>
      {children}
      {isActive && <motion.div layoutId="indicator" className={css.indicator} />}
    </div>
  )
}

function Navigation({ island, setIsland }) {
  const handleSearch = () => {
    setIsland({ type: SEARCH, previous: island })
  }

  const setView = view => {
    setIsland(island => ({ ...island, active: view }))
  }

  return (
    <motion.div
      initial={{ sclae: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      className={css.navigation}>
      <NavLink isActive={island.active === HOME} onClick={() => setView(HOME)}>
        <HomeIcon />
      </NavLink>
      <NavLink onClick={handleSearch}>
        <motion.div className={css.icon} layoutId="search">
          <MagnifyingGlassIcon />
        </motion.div>
      </NavLink>
      <NavLink isActive={island.active === ARCHIVE} onClick={() => setView(ARCHIVE)}>
        <ArchiveBoxIcon />
      </NavLink>
      <NavLink isActive={island.active === SETTINGS} onClick={() => setView(SETTINGS)}>
        <Cog8ToothIcon />
      </NavLink>
    </motion.div>
  )
}

function Search({ island, setIsland }) {
  const handleBlur = () => {
    setIsland(island.previous)
  }

  return (
    <div className={css.search}>
      <motion.div className={css.icon} layoutId="search">
        <MagnifyingGlassIcon />
      </motion.div>
      <motion.input
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
        autoFocus onBlur={handleBlur} placeholder="Search..." />
    </div>
  )
}

function Notification() {
  return (
    <motion.div
      initial={{ opacity: 0, x: 30 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -30 }}
      layout="position" className={css.notification}>
      <div className={css.avatar} style={{ backgroundImage: `url(${image})` }} />
      <div>
        <h6 className={css.notificationTitle}>Jason Roland sent you a message.</h6>
        <p className={css.notificationText}>
          Hey! Have just finished up work on...
        </p>
      </div>
    </motion.div>
  )
}

export default function DynamicIsland() {
  const [island, setIsland] = useState({ type: NAVIGATION, active: HOME })

  const triggerNotification = () => {
    setIsland({ type: NOTIFICATION })

    setTimeout(() => {
      setIsland(island)
    }, 3000);
  }

  return (
    <div className={css.page}>
      <motion.div layoutId="island" style={{ borderRadius: 30 }} className={css.island}>
        <AnimatePresence>
          {island.type === NOTIFICATION && <Notification island={island} setIsland={setIsland} />}
          {island.type === NAVIGATION && <Navigation island={island} setIsland={setIsland} />}
          {island.type === SEARCH && <Search island={island} setIsland={setIsland} />}
        </AnimatePresence>
      </motion.div>
      <button className={css.button} onClick={triggerNotification}>Trigger Notification</button>
    </div>
  )
}
