import css from "./styles.module.css";
import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { vertex, fragment } from "./points/shader";
import defaultImage from "./computer.jpg";
import { button, useControls } from "leva";
import { useImageData } from "./useImageData";
import { useCursorPosition } from "./useCursorPosition";

const dpr = Math.min(window.devicePixelRatio, 2);

function randomNumberBetween(min, max) {
  return Math.random() * (max - min) + min;
}

export function EncryptedDotsImage() {
  const [id, setId] = useState(0);
  const [image, setImage] = useState(null);

  const controls = useControls({
    uShadows: "#8000ff",
    uColor: "#ead7ff",
    detail: {
      value: 200,
      min: 100,
      max: 512,
      step: 10,
    },
    particleSize: {
      value: 0.03,
      min: 0.01,
      max: 0.1,
      step: 0.001,
    },
    particleGrowth: {
      value: 0.03,
      min: 0.01,
      max: 0.15,
      step: 0.005,
    },
    cursorSize: {
      value: 0.15,
      min: 0.05,
      max: 0.5,
      step: 0.01,
    },
    image: button(() => {
      document.querySelector("#selectImage").click();
    }),
    restart: button(() => {
      setId((current) => current + 1);
    }),
  });

  useEffect(() => {
    if (!image) {
      const img = new Image();
      img.src = defaultImage;
      img.onload = () => {
        setImage(img);
      };
    }
  }, [image]);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        setId(id + 1);
        setImage(img);
      };
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className={css.container}>
      <div className={css.imageSelection}>
        <input
          id="selectImage"
          type="file"
          accept="image/*"
          onChange={handleChange}
        />
      </div>
      <Canvas
        key={id}
        dpr={dpr}
        camera={{
          fov: 35,
          aspect: window.innerWidth / window.innerHeight,
          near: 0.1,
          far: 100,
          position: [0, 0, 15],
        }}
      >
        {image && <Particles controls={controls} image={image} />}
      </Canvas>
    </div>
  );
}

function Particles({ controls, image }) {
  const mat = useRef();
  const intersectionPlane = useRef();
  const imageData = useImageData(image);
  const cursorData = useCursorPosition(intersectionPlane, controls.cursorSize);

  const uniforms = useMemo(() => {
    return {
      uTime: {
        value: 0.0,
      },
      uParticleSize: {
        value: controls.particleSize,
      },
      uParticleGrowth: {
        value: controls.particleGrowth,
      },
      uShadows: {
        value: new THREE.Color(controls.uShadows),
      },
      uColor: {
        value: new THREE.Color(controls.uColor),
      },
      uResolution: new THREE.Uniform(
        new THREE.Vector2(window.innerWidth * dpr, window.innerHeight * dpr),
      ),
      uCursorTexture: new THREE.Uniform(cursorData.texture.current),
      uPictureTexture: new THREE.Uniform(imageData.texture.current),
    };
  }, []);

  const { geometry } = useMemo(() => {
    const aspect = window.innerWidth / window.innerHeight;

    const plane = new THREE.PlaneGeometry(
      10 * aspect,
      10,
      controls.detail,
      controls.detail,
    );
    plane.setIndex(null); // remove index to reduce particle count
    plane.deleteAttribute("normal"); // we don't need normals

    const particleCount = plane.attributes.position.count;
    const random = new Float32Array(particleCount);
    const shuffled = new Float32Array(particleCount * 3);
    const xOffset = new Float32Array(particleCount);
    const yOffset = new Float32Array(particleCount);
    const delay = new Float32Array(particleCount);
    const duration = new Float32Array(particleCount);
    const offset = new Float32Array(particleCount * 2);

    // grid of points
    for (let i = 0; i < particleCount; i++) {
      random[i] = randomNumberBetween(0.0, 1.0);
      xOffset[i] = randomNumberBetween(-1.0, 1.0);
      yOffset[i] = randomNumberBetween(-1.0, 1.0);
      delay[i] = randomNumberBetween(0.0, 3.0);
      duration[i] = randomNumberBetween(0.05, 0.4);

      const randomIndex = Math.floor(randomNumberBetween(0, particleCount));

      const i3 = i * 3;
      const r3 = randomIndex * 3;
      shuffled[i3 + 0] = plane.attributes.position.array[r3];
      shuffled[i3 + 1] = plane.attributes.position.array[r3 + 1];
      shuffled[i3 + 2] = plane.attributes.position.array[r3 + 2];

      if ([true, false][Math.floor(randomNumberBetween(0, 2))]) {
        offset[i3 + 0] = randomNumberBetween(-1.0, 1.0);
        offset[i3 + 1] = 0;
      } else {
        offset[i3 + 0] = 0;
        offset[i3 + 1] = randomNumberBetween(-1.0, 1.0);
      }
    }

    plane.setAttribute("random", new THREE.BufferAttribute(random, 1));
    plane.setAttribute("shuffled", new THREE.BufferAttribute(shuffled, 3));
    plane.setAttribute("xOffset", new THREE.BufferAttribute(xOffset, 1));
    plane.setAttribute("yOffset", new THREE.BufferAttribute(yOffset, 1));
    plane.setAttribute("delay", new THREE.BufferAttribute(delay, 1));
    plane.setAttribute("duration", new THREE.BufferAttribute(duration, 1));
    plane.setAttribute("offset", new THREE.BufferAttribute(offset, 2));

    return { geometry: plane };
  }, [image, controls.detail]);

  useFrame((state) => {
    const { clock } = state;
    mat.current.uniforms.uTime.value = clock.getElapsedTime();
    mat.current.uniforms.uColor.value = new THREE.Color(controls.uColor);
    mat.current.uniforms.uShadows.value = new THREE.Color(controls.uShadows);
    mat.current.uniforms.uParticleSize.value = controls.particleSize;
    mat.current.uniforms.uParticleGrowth.value = controls.particleGrowth;
  });

  return (
    <>
      <points>
        <bufferGeometry attach="geometry" {...geometry} />
        <shaderMaterial
          ref={mat}
          vertexShader={vertex}
          fragmentShader={fragment}
          uniforms={uniforms}
        />
      </points>
      <mesh ref={intersectionPlane} visible={false}>
        <planeBufferGeometry
          args={[(10 * window.innerWidth) / window.innerHeight, 10]}
        />
      </mesh>
    </>
  );
}
// <planeBufferGeometry args={[10, 10, 150, 150]} />
