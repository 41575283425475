// https://codesandbox.io/s/zgsyn
//
import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import { useRef, useMemo, useEffect, useState } from "react";
import { OrbitControls } from "@react-three/drei";
import { useControls } from "leva";
import vertexShader from "./vertex";
import fragmentShader from "./fragment";
import PlayButton from "./PlayButton";
import Credit from "../../components/Credit";

const AudioContext = window.AudioContext || window.webkitAudioContext;

function Sphere({ playing, audio }) {
  const mat = useRef();
  const mesh = useRef();
  const contextRef = useRef();
  const audioAnalyser = useRef();

  useEffect(() => {
    if (playing && !contextRef.current) {
      const context = new AudioContext();
      const analyser = context.createAnalyser();
      const source = context.createMediaElementSource(audio);
      source.connect(analyser);
      analyser.connect(context.destination);
      contextRef.current = context;
      audioAnalyser.current = analyser;
    }
  }, [playing]);

  const controls = useControls({
    uColor: "#833600",
    uColor2: "#022230",
    intensity: {
      value: 150.0,
      min: 0.0,
      max: 200.0,
    },
    uFrequency: {
      value: 2.6,
      min: 1.0,
      max: 5.0,
    },
  });

  useFrame(({ clock }) => {
    if (playing) {
      const analyser = audioAnalyser.current;
      const data = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteTimeDomainData(data);
      const t = Math.max(...data) - 128;
      const a = t / (250.0 - controls.intensity);
      mat.current.uniforms.uAmount.value = THREE.MathUtils.lerp(
        mat.current.uniforms.uAmount.value,
        a,
        0.05
      );
      mat.current.uniforms.uFrequency.value = controls.uFrequency;
      mesh.current.position.z = THREE.MathUtils.lerp(
        mesh.current.position.z,
        0 + a * 2,
        0.1
      );
    } else {
      mat.current.uniforms.uAmount.value = 0.02;
      mat.current.uniforms.uFrequency.value = 1.0;
      mesh.current.position.z = THREE.MathUtils.lerp(
        mesh.current.position.z,
        -1,
        0.1
      );
    }

    mat.current.uniforms.uTime.value = clock.elapsedTime;
    mat.current.uniforms.uColor.value = new THREE.Color(controls.uColor);
    mat.current.uniforms.uColor2.value = new THREE.Color(controls.uColor2);
  });

  const uniforms = useMemo(() => {
    return {
      uTime: {
        value: 0.0,
      },
      uColor: {
        value: controls.uColor,
      },
      uColor2: {
        value: controls.uColor2,
      },
      uAmount: {
        value: 0.0,
      },
      uFrequency: {
        value: controls.uFrequency,
      },
    };
  }, []);

  return (
    <points ref={mesh} position-z={-1}>
      <icosahedronGeometry args={[1, 64]} />
      <shaderMaterial
        ref={mat}
        vertexShader={vertexShader}
        fragmentShader={fragmentShader}
        uniforms={uniforms}
        blending={THREE.AdditiveBlending}
        depthWrite={false}
      />
    </points>
  );
}

export default function Example() {
  const [playing, setPlaying] = useState(false);
  const audio = useRef(new Audio("/track.mp3"));
  const { background } = useControls({
    background: "#6e169d",
  });

  useEffect(() => {
    const onPlay = () => setPlaying(true);
    const onStop = () => setPlaying(false);
    audio.current.addEventListener("play", onPlay);
    audio.current.addEventListener("pause", onStop);
    return () => {
      audio.current.removeEventListener("play", onPlay);
      audio.current.removeEventListener("pause", onStop);
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          display: "grid",
          placeItems: "center",
          zIndex: 10,
        }}
      >
        <PlayButton playing={playing} audio={audio.current} />
      </div>
      <Canvas camera={{ fov: 30, position: [0, 0, 6] }}>
        <color attach="background" args={[background]} />
        <Sphere playing={playing} audio={audio.current} />
      </Canvas>
      <Credit />
    </>
  );
}
