import css from "./SplitWindow.module.css"
import { AnimatePresence, motion } from "framer-motion"
import { createContext, createElement, useEffect, useCallback, useContext, useMemo, useState } from "react";

function generateID() {
  return Math.random().toString(36).substr(2, 9);
}

const Frames = createContext();
const FrameContext = createContext();
const DragContext = createContext();

function DragProvider({ children }) {
  const { addFrame } = useContext(Frames)
  const [dragging, setDragging] = useState(null);
  const [position, setPosition] = useState(null);
  const [canDrop, setCanDrop] = useState(false);

  const startDragging = useCallback((attributes) => {
    const id = generateID();
    setDragging({ id, ...attributes })
  }, [])

  useEffect(() => {
    const handleMouseUp = () => {
      if (canDrop) {
        addFrame(dragging.id, { view: dragging.to })
      }

      setDragging(null)
      setCanDrop(false);
    }

    const handleMouseMove = e => {
      setPosition({ x: e.clientX, y: e.clientY })
    }

    if (dragging) {
      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [dragging, canDrop])


  return (
    <DragContext.Provider value={{ dragging, startDragging, canDrop }}>
      {dragging && (
        <div className={css.dragging} style={{ top: position?.y, left: position?.x }}>
          {dragging.children}
        </div>
      )}
      <div className={css.dropzone} onMouseOver={() => dragging && setCanDrop(true)} onMouseLeave={() => setCanDrop(false)} />
      {children}
    </DragContext.Provider>
  )
}

function useFrames() {
  const [frames, setFrames] = useState([
    {
      id: generateID(),
      view: "Index"
    },
  ])

  const updateFrame = useCallback((id, attributes) => {
    setFrames(existing => {
      return existing.map(frame => {
        if (frame.id === id) {
          return { ...frame, ...attributes }
        }

        return frame
      })
    })
  }, [])

  const addFrame = useCallback((id, attributes) => {
    setFrames(existing => {
      return [
        ...existing,
        { id, ...attributes }
      ]
    })
  }, [])

  return { frames, addFrame, updateFrame }
}

function Link({ to, children }) {
  const { startDragging } = useContext(DragContext)
  const { updateFrame } = useContext(Frames);
  const { id } = useContext(FrameContext)

  const handleClick = () => {
    updateFrame(id, { view: to })
  }

  const handleMouseDown = (e) => {
    startDragging({ to, children })
  }

  return <button onMouseDown={handleMouseDown} onClick={handleClick}>{children}</button>
}

function Index() {
  return (
    <div>
      <h1>This is the index</h1>
      <Link to="Inbox">Inbox</Link>
    </div>
  )
}

function Inbox() {
  return (
    <div>
      <h1>Inbox</h1>
      <Link to="Index">Back</Link>
    </div>
  )
}

const views = {
  Index,
  Inbox
}

function DropPreview() {
  const { canDrop, dragging } = useContext(DragContext)

  return (
    <AnimatePresence>
      {canDrop && (
        <motion.div layoutId={dragging.id} initial={{ width: 0 }} animate={{ width: 80 }} exit={{ width: 0 }} className={css.dropPreview} />
      )}
    </AnimatePresence>
  )

}

export default function SplitWindow() {
  const state = useFrames()
  return (
    <Frames.Provider value={state}>
      <DragProvider>
        <div className={css.page}>
          {state.frames.map(frame => {
            return (
              <motion.div transition={{ duration: 0.9 }} layoutId={frame.id} key={frame.id} className={css.frame}>
                <motion.div layout="position">
                  <FrameContext.Provider value={frame}>
                    {createElement(views[frame.view])}
                  </FrameContext.Provider>
                </motion.div>
              </motion.div>
            )
          })}
          <DropPreview />
        </div>
      </DragProvider>
    </Frames.Provider>
  )
}
