import * as Tone from "tone";
import { createContext, useState, useMemo, useEffect, useCallback } from "react";

export const SequencerContext = createContext();

export default function SequencerProvider({ children }) {
  const [beat, setBeat] = useState(null);
  const [notes, setNotes] = useState({ "C": [0, 2, 4, 6, 8, 10, 12, 14], "C#": [], "D": [], "D#": [], "E": [], "F": [], "F#": [], "G": [], "G#": [], "A": [], "A#": [], "B": [], })
  const [playing, setPlaying] = useState(false);
  const [started, setStarted] = useState(false);

  const synths = useMemo(() => {
    return new Array(12).fill(0).map(() => new Tone.Synth().toDestination())
  }, [])

  const toggleNote = useCallback((note, beat) => {
    setNotes(existing => {
      const active = existing[note];

      if (active.includes(beat)) {
        return { ...existing, [note]: active.filter(b => b !== beat) }
      }

      return { ...existing, [note]: [...active, beat] }
    })
  }, [])

  useEffect(() => {
    if (!started) return;

    const loop = new Tone.Sequence((time, col) => {
      setBeat(col)

      Object.keys(notes).forEach((note, index) => {
        const synth = synths[index]
        const beats = notes[note];
        if (beats.includes(col)) {
          synth.triggerAttackRelease(`${note}4`, "8n", time)
        }
      })
    }, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15])

    loop.start(0)

    return () => loop.dispose()
  }, [started, notes, synths])

  const start = useCallback(async () => {
    if (!started) {
      await Tone.start();
      setStarted(true)
    }

    setPlaying(true)
    Tone.Transport.start()
  }, [started, playing])

  const value = { start, notes, toggleNote, beat }

  return (
    <SequencerContext.Provider value={value}>
      {children}
    </SequencerContext.Provider>
  )
}
