import { motion } from "framer-motion"
import styles from "./styles.module.css"
import { useSequencer } from "./useSequencer"

function Beat({ onClick, isActive, isArmed }) {
  return (
    <div onClick={onClick} className={styles.trackBeat} data-active={isActive} data-armed={isArmed}>
      <motion.div
        className={styles.trackDot}
        animate={{
          scale: isArmed ? 1.5 : 1,
          background: isArmed ? "#FFCBAD" : "transparent",
          borderColor: isArmed ? "#FFCBAD" : null
        }}
      />

      <motion.div className={styles.selector} />
    </div>
  )
}

function Note({ note }) {
  const { beat, notes, toggleNote } = useSequencer();
  const beats = notes[note]

  return (
    <div className={styles.track}>
      <div className={styles.trackNote}>{note}</div>
      <div className={styles.trackBeats}>
        {new Array(16).fill(0).map((_, index) => (
          <Beat onClick={() => toggleNote(note, index)} key={index} isActive={index === beat} isArmed={beats.includes(index)} />
        ))}
      </div>
    </div>
  )
}

export default function Grid() {
  const { notes } = useSequencer();

  return (
    <div className={styles.gridContainer}>
      <div className={styles.grid}>
        {Object.keys(notes).map(note => (
          <Note key={note} note={note} />
        ))}
      </div>
    </div>
  )
}
