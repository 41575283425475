import css from "./styles.module.css";
import * as THREE from "three";
import { OrbitControls } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef } from "react";
import { vertex, fragment } from "./points/shader";
import kido from "./kido.jpg";
import glowSrc from "./glow.png";

const dpr = Math.min(window.devicePixelRatio, 2);

function useMouseTexture() {
  const ctx = useRef();
  const glow = useRef();
  const canvas = useRef();

  useEffect(() => {
    if (ctx.current) return;

    canvas.current = document.createElement("canvas");
    canvas.current.width = 128;
    canvas.current.height = 128;
    // all of these are just to see the canvas to debug.
    canvas.current.style.position = "fixed";
    canvas.current.style.width = "256px";
    canvas.current.style.height = "256px";
    canvas.current.style.top = "0";
    canvas.current.style.left = "0";
    canvas.current.style.zIndex = 10;
    canvas.current.style.pointerEvents = "none";
    // add to the dom to debug, but this isn't actually required.
    document.body.appendChild(canvas.current);

    ctx.current = canvas.current.getContext("2d");

    // add a black background
    ctx.current.fillStyle = "black";
    ctx.current.fillRect(0, 0, 128, 128);

    glow.current = new Image();
    glow.current.src = glowSrc;
  }, []);

  const fade = () => {
    if (!ctx.current) return;
    ctx.current.globalCompositeOperation = "source-over";
    ctx.current.fillStyle = "rgba(0, 0, 0, 0.025)";
    ctx.current.fillRect(0, 0, 128, 128);
  };

  const draw = (x, y, alpha) => {
    if (!ctx.current) return;

    const size = canvas.current.width * 0.25;
    ctx.current.globalCompositeOperation = "lighten";
    ctx.current.globalAlpha = alpha;
    ctx.current.drawImage(
      glow.current,
      x - size * 0.5,
      y - size * 0.5,
      size,
      size,
    );
  };

  return { ctx, canvas, draw, fade };
}

const useMouseClipSpace = () => {
  const cursor = useRef(new THREE.Vector2(9999, 9999));

  useEffect(() => {
    const onMouseMove = (e) => {
      cursor.current.x = (e.clientX / window.innerWidth) * 2 - 1;
      cursor.current.y = -(e.clientY / window.innerHeight) * 2 + 1;
    };

    document.addEventListener("mousemove", onMouseMove);
    return () => document.removeEventListener("mousemove", onMouseMove);
  }, []);

  return { cursor };
};

export function DotsImage() {
  return (
    <div className={css.container}>
      <Canvas
        dpr={dpr}
        camera={{
          fov: 35,
          aspect: window.innerWidth / window.innerHeight,
          near: 0.1,
          far: 100,
          position: [0, 0, 18],
        }}
      >
        <OrbitControls />
        <Particles />
      </Canvas>
    </div>
  );
}

function Particles() {
  const intersectionPlane = useRef();
  const { canvas, fade, draw } = useMouseTexture();
  const { cursor } = useMouseClipSpace();
  const canvasTexture = useRef(new THREE.CanvasTexture());
  const previousCursor = useRef(new THREE.Vector2(9999, 9999));

  useFrame(({ raycaster }) => {
    if (!raycaster.camera) return;
    raycaster.setFromCamera(cursor.current, raycaster.camera);
    const intersects = raycaster.intersectObject(intersectionPlane.current);

    previousCursor.current.copy(cursor.current);

    fade();
    if (intersects.length > 0) {
      const { uv } = intersects[0];
      draw(uv.x * 128, (1 - uv.y) * 128);
    }

    canvasTexture.current.image = canvas.current;
    canvasTexture.current.needsUpdate = true;
  });

  const uniforms = useMemo(() => {
    return {
      uResolution: new THREE.Uniform(
        new THREE.Vector2(window.innerWidth * dpr, window.innerHeight * dpr),
      ),
      uPictureTexture: new THREE.Uniform(new THREE.TextureLoader().load(kido)),
      uDisplacementTexture: new THREE.Uniform(canvasTexture.current),
    };
  }, []);

  const { geometry } = useMemo(() => {
    const geometry = new THREE.PlaneBufferGeometry(10, 10, 128, 128);
    geometry.setIndex(null); // remove index to reduce particle count
    geometry.deleteAttribute("normal"); // we don't need normals
    const random = new Float32Array(geometry.attributes.position.count);
    const angles = new Float32Array(geometry.attributes.position.count);

    for (let i = 0; i < random.length; i++) {
      random[i] = Math.random();
      angles[i] = Math.random() * Math.PI * 2;
    }

    geometry.setAttribute("aRandom", new THREE.BufferAttribute(random, 1));
    geometry.setAttribute("aAngle", new THREE.BufferAttribute(angles, 1));

    return { geometry };
  }, []);

  return (
    <>
      <points>
        <bufferGeometry attach="geometry" {...geometry} />
        <shaderMaterial
          vertexShader={vertex}
          fragmentShader={fragment}
          uniforms={uniforms}
        />
      </points>
      {/* Create another plane mesh to raycast against. */}
      <mesh ref={intersectionPlane} visible={false}>
        <planeBufferGeometry args={[10, 10]} />
      </mesh>
    </>
  );
}
