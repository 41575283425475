import { AnimatePresence, motion } from "framer-motion"
import { useCallback, useEffect, useMemo, useState } from "react"
import css from "./Multitask.module.css"

function randomID() {
  return Math.random().toString(36).substr(2, 9);
}

function useBackgroundColor(color) {
  useEffect(() => {
    const before = document.body.style.backgroundColor;
    document.body.style.backgroundColor = color

    return () => {
      document.body.style.backgroundColor = before
    }
  }, [])
}

function useTasks() {
  const [current, setCurrent] = useState(0);
  const [tasks, setTasks] = useState([
    "Tasks",
    "Backlog",
    randomID(),
    randomID(),
  ])

  const addTask = useCallback((task) => {
    setTasks([...tasks, task])
    setCurrent(tasks.length)
  }, [tasks])

  const setActive = useCallback(task => {
    const index = tasks.indexOf(task)
    setCurrent(index);
  }, [tasks])

  const currentTask = useMemo(() => {
    return tasks[current];
  }, [current])

  return { tasks, addTask, currentTask, setActive }
}

function Switcher({ state }) {
  return (
    <motion.div className={css.switcher} layout>
      {state.tasks.map(task => (
        <motion.div
          onClick={() => state.setActive(task)}
          key={task}
          animate={{
            width: state.currentTask === task ? 100 : 80,
            height: state.currentTask === task ? 80 : 60
          }}
          layoutId={task} className={css.task} data-active={state.currentTask === task}>
          {task}
        </motion.div>
      ))}
      <button onClick={() => state.addTask(randomID())}>Add</button>
    </motion.div>
  )
}

function App({ task }) {
  return (
    <motion.div className={css.app}
      initial={{ x: "100%", scale: 0.9 }}
      animate={{ x: "0%", scale: 1 }}
      exit={{ x: "-100%", scale: 0.9 }}
      transition={{ duration: 0.7 }}
    >
      <div className={css.sidebar}>
        <div className={css.sidebarItem}>
          My Tasks
        </div>
        <div className={css.sidebarItem}>
          Inbox
        </div>
        <div className={css.sidebarItem}>
          Favourites
        </div>
      </div>
      {task}
    </motion.div>
  )
}

export default function Multitask() {
  const state = useTasks();
  useBackgroundColor("#111")

  return (
    <>
      <Switcher state={state} />
      <AnimatePresence>
        {state.tasks.map(t => (
          t === state.currentTask && <App key={t} task={t} />
        ))}
      </AnimatePresence>
    </>
  )
}

