// https://codesandbox.io/s/zgsyn
//
import * as THREE from "three";
import { Canvas, createPortal, useFrame } from "@react-three/fiber";
import { useRef, useLayoutEffect, useMemo } from "react";
import { OrbitControls, useFBO } from "@react-three/drei";
import { useControls } from "leva";
import "./simulationShader";
import "./pointsShader";
// import vertexShader from "./vertex";
// import fragmentShader from "./fragment";
//
const SIZE = 512;

function Scene() {
  const sim = useRef();
  const renderRef = useRef();
  const scene = useMemo(() => new THREE.Scene(), []);
  const camera = useMemo(
    () => new THREE.OrthographicCamera(-1, 1, 1, -1, 1 / Math.pow(2, 53), 1),
    []
  );
  const fbo = useFBO(SIZE, SIZE, {
    minFilter: THREE.NearestFilter,
    magFilter: THREE.NearestFilter,
    format: THREE.RGBAFormat,
    type: THREE.FloatType,
  });

  const controls = useControls({
    focus: { value: 5.4, min: 3, max: 7, step: 0.01 },
    aperture: { value: 3.0, min: 1, max: 5.6, step: 0.1 },
    fov: { value: 10, min: 0, max: 200 },
    curl: { value: 0.01, min: 0.01, max: 0.5, step: 0.01 },
    color: { value: "#9c21cb" },
    highlight: { value: "#DE9EFF" },
  });

  const positions = useMemo(
    () =>
      new Float32Array([
        -1, -1, 0, 1, -1, 0, 1, 1, 0, -1, -1, 0, 1, 1, 0, -1, 1, 0,
      ]),
    []
  );

  const uvs = useMemo(
    () => new Float32Array([0, 1, 1, 1, 1, 0, 0, 1, 1, 0, 0, 0]),
    []
  );

  useFrame(({ clock, gl }) => {
    gl.setRenderTarget(fbo);
    gl.clear();
    gl.render(scene, camera);
    gl.setRenderTarget(null);
    renderRef.current.uniforms.uBlur.value = (5.6 - controls.aperture) * 3.0;
    renderRef.current.uniforms.uFov.value = controls.fov;
    renderRef.current.uniforms.uFocus.value = controls.focus;
    renderRef.current.uniforms.uPositions.value = fbo.texture;
    renderRef.current.uniforms.uColor.value = new THREE.Color(controls.color);
    renderRef.current.uniforms.uHighlight.value = new THREE.Color(
      controls.highlight
    );
    sim.current.uniforms.uTime.value = clock.elapsedTime;
    sim.current.uniforms.uCurlFrequency.value = controls.curl;
  });

  // Normalize points
  const particles = useMemo(() => {
    const length = SIZE * SIZE;
    const particles = new Float32Array(length * 3);
    for (let i = 0; i < length; i++) {
      let i3 = i * 3;
      particles[i3 + 0] = (i % SIZE) / SIZE;
      particles[i3 + 1] = i / SIZE / SIZE;
    }
    return particles;
  }, []);

  return (
    <>
      {createPortal(
        <mesh>
          <simulationMaterial ref={sim} />
          <bufferGeometry>
            <bufferAttribute
              attach="attributes-position"
              count={positions.length / 3}
              array={positions}
              itemSize={3}
            />
            <bufferAttribute
              attach="attributes-uv"
              count={uvs.length / 2}
              array={uvs}
              itemSize={2}
            />
          </bufferGeometry>
        </mesh>,
        scene
      )}
      <points>
        <mainPointsShader ref={renderRef} />
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={particles.length / 3}
            array={particles}
            itemSize={3}
          />
        </bufferGeometry>
      </points>
    </>
  );
}

export default function Example() {
  return (
    <Canvas camera={{ fov: 25, position: [0, 0, 6] }}>
      <OrbitControls
        makeDefault
        autoRotate
        autoRotateSpeed={0.5}
        zoomSpeed={0.1}
      />
      <color attach="background" args={["black"]} />
      <Scene />
    </Canvas>
  );
}
