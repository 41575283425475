export default function Credit() {
  return (
    <div className="credit">
      Made by{" "}
      <a target="_blank" rel="noreferrer" href="https://twitter.com/thomasauros">
        Thomas Cullen
      </a>
    </div>
  );
}
