import { useFrame } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { Vector2 } from "three";
import glowSrc from "./glow.png";
import { CanvasTexture } from "three";

export function useCursorPosition(target, cursorSize) {
  const canvas = useRef();
  const glow = useRef(new Image());
  const cursor = useRef(new Vector2(9999, 9999));
  const previousCursor = useRef(new Vector2(9999, 9999));
  const texture = useRef(new CanvasTexture());

  useEffect(() => {
    glow.current.src = glowSrc;

    canvas.current = document.createElement("canvas");
    const ctx = canvas.current.getContext("2d");

    canvas.current.width = window.innerWidth;
    canvas.current.height = window.innerHeight;

    ctx.fillStyle = "black";
    ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);

    const handleMouseMove = (e) => {
      cursor.current.x = (e.clientX / window.innerWidth) * 2 - 1;
      cursor.current.y = -(e.clientY / window.innerHeight) * 2 + 1;
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  const fade = () => {
    if (!canvas.current) return;
    const ctx = canvas.current.getContext("2d");
    ctx.globalCompositeOperation = "source-over";
    ctx.fillStyle = "rgba(0, 0, 0, 0.025)";
    ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
  };

  const draw = (x, y) => {
    if (!canvas.current) return;

    const size = canvas.current.width * cursorSize;
    const ctx = canvas.current.getContext("2d");
    ctx.globalCompositeOperation = "lighten";
    ctx.drawImage(glow.current, x - size * 0.5, y - size * 0.5, size, size);
  };

  useFrame(({ raycaster }) => {
    if (!raycaster.camera) return;
    raycaster.setFromCamera(cursor.current, raycaster.camera);
    const intersects = raycaster.intersectObject(target.current);
    const distance = cursor.current.distanceTo(previousCursor.current);

    previousCursor.current.copy(cursor.current);

    fade();
    if (intersects.length > 0 && distance > 0.01) {
      const { uv } = intersects[0];
      draw(uv.x * window.innerWidth, (1 - uv.y) * window.innerHeight);
    }

    texture.current.image = canvas.current;
    texture.current.needsUpdate = true;
  });

  return { texture };
}
