import React from "react";
import ReactDOM from "react-dom/client";
import Visualizer from "./experiments/ParticleSphereVisualizer";
import Campsite from "./experiments/Campsite";
import LightTubes from "./experiments/LightTubes";
import SplitWindow from "./experiments/SplitWindow";
import SphereLines from "./experiments/SphereLines";
import LocalServer from "./experiments/LocalServer";
import ParticleSphere from "./experiments/ParticleSphere";
import Multitask from "./experiments/Multitask";
import Dotted from "./experiments/Dotted";
import DynamicIsland from "./experiments/DynamicIsland";
import KeyboardShortcuts from "./experiments/KeyboardShortcuts";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Checkbox from "./experiments/Checkbox";
import Notes from "./experiments/Notes";
import { DotsImage } from "./experiments/DotsImage";
import { ParticleMorphing } from "./experiments/ParticleMorphing";
import { EncryptedDotsImage } from "./experiments/DotsEncryptedImage";

const router = createBrowserRouter([
  {
    path: "/visualizer",
    element: <Visualizer />,
  },
  {
    path: "/campsite",
    element: <Campsite />,
  },
  {
    path: "/lighttrails",
    element: <LightTubes />,
  },
  {
    path: "/sphere",
    element: <ParticleSphere />,
  },
  {
    path: "/keyboard-shortcuts",
    element: <KeyboardShortcuts />,
  },
  {
    path: "/multitask",
    element: <Multitask />,
  },
  {
    path: "/split",
    element: <SplitWindow />,
  },
  {
    path: "/local-server",
    element: <LocalServer />,
  },
  {
    path: "/dotted",
    element: <Dotted />,
  },
  {
    path: "/list",
    element: <Checkbox />,
  },
  {
    path: "/notes",
    element: <Notes />,
  },
  {
    path: "/dynamic-island",
    element: <DynamicIsland />,
  },
  {
    path: "/image-reveal",
    element: <EncryptedDotsImage />,
  },
  {
    path: "/particle-morph",
    element: <ParticleMorphing />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
