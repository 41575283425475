import { useEffect, useRef } from "react";
import { CanvasTexture } from "three";

export function useImageData(img) {
  const texture = useRef(new CanvasTexture());

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const imgRatio = img.width / img.height;
    const imgHeight = canvas.height;
    const imgWidth = canvas.height * imgRatio;
    const x = canvas.width / 2 - imgWidth / 2;
    ctx.drawImage(img, x, 0, imgWidth, imgHeight);

    const grd = ctx.createLinearGradient(x, 0, x + imgWidth, 0);
    grd.addColorStop(0, "rgba(0,0,0,1)");
    grd.addColorStop(0.2, "rgba(0,0,0,0)");
    grd.addColorStop(0.8, "rgba(0,0,0,0)");
    grd.addColorStop(1, "rgba(0,0,0,1)");

    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      let avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      data[i] = avg;
      data[i + 1] = avg;
      data[i + 2] = avg;
    }

    ctx.putImageData(imageData, 0, 0);

    texture.current.image = canvas;
    texture.current.needsUpdate = true;
  }, [img]);

  return { texture };
}
