import React, { useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  Center,
  Float,
  OrbitControls,
  PerspectiveCamera,
  PresentationControls,
  useGLTF,
  useTexture,
} from "@react-three/drei";
import { useControls } from "leva";
import { EffectComposer, DepthOfField } from "@react-three/postprocessing";

const ROCKS = [
  { floatingRange: [-0.06, 0] },
  { floatingRange: [-0.06, 0] },
  { floatingRange: [-0.06, 0] },
  { floatingRange: [-0.06, 0] },
  { floatingRange: [-0.06, 0] },
  { floatingRange: [-0.06, 0] },
  { floatingRange: [-0.06, 0] },
  { floatingRange: [-0.06, 0] },
];

function Scene() {
  const model = useGLTF("/campsite.glb");
  const bake = useTexture("/campsiteBaked.jpg");
  const { background } = useControls({
    background: "#291a48",
  });
  bake.flipY = false;

  return (
    <>
      <ambientLight />
      <PerspectiveCamera
        makeDefault
        position={[0, 0.7, 2.5]}
        rotation={[-0.3, 0, 0]}
      />
      <color args={[background]} attach="background" />
      <pointLight position={[10, 10, 10]} />
      <PresentationControls
        global
        zoom={0.8}
        snap
        rotation={[0, 0, 0]}
        polar={[0, Math.PI / 4]}
        azimuth={[-Math.PI / 4, Math.PI / 4]}
      >
        <Center>
          <mesh
            geometry={model.nodes.baked.geometry}
            position={model.nodes.baked.position}
          >
            <meshBasicMaterial map={bake} />
          </mesh>
          {ROCKS.map((config, i) => (
            <mesh
              key={i}
              geometry={model.nodes[`rock${i + 1}`].geometry}
              position={model.nodes[`rock${i + 1}`].position}
            >
              <meshBasicMaterial map={bake} />
            </mesh>
          ))}
        </Center>
      </PresentationControls>
      <EffectComposer>
        <DepthOfField focusDistance={0} focalLength={0.008} bokehScale={2} />
      </EffectComposer>
    </>
  );
}

function Campsite() {
  return (
    <Canvas>
      <Scene />
    </Canvas>
  );
}

export default Campsite;
