import React from "react";
import { motion } from "framer-motion";

function PlayButton({ playing, audio }) {
  const handleClick = () => {
    if (playing) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  return (
    <motion.div
      onClick={handleClick}
      style={{ cursor: "pointer" }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {playing ? <Pause /> : <Play />}
    </motion.div>
  );
}

function Play() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      fill="none"
      viewBox="0 0 80 80"
    >
      <g clipPath="url(#clip0_11_2)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M40 80c22.091 0 40-17.909 40-40S62.091 0 40 0 0 17.909 0 40s17.909 40 40 40zm13.41-36.808a3.98 3.98 0 000-6.373l-15.5-11.916c-2.78-2.137-6.902-.236-6.903 3.185l-.006 23.824c0 3.421 4.12 5.325 6.902 3.188l15.506-11.908z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_11_2">
          <path fill="#fff" d="M0 0H80V80H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function Pause() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="50"
      fill="none"
      viewBox="0 0 80 80"
    >
      <g clipPath="url(#clip0_11_8)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M40 80c22.091 0 40-17.909 40-40S62.091 0 40 0 0 17.909 0 40s17.909 40 40 40zm-8.5-56a3.5 3.5 0 00-3.5 3.5v25a3.5 3.5 0 107 0v-25a3.5 3.5 0 00-3.5-3.5zm17 0a3.5 3.5 0 00-3.5 3.5v25a3.5 3.5 0 107 0v-25a3.5 3.5 0 00-3.5-3.5z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_11_8">
          <path fill="#fff" d="M0 0H80V80H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlayButton;
