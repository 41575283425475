import glsl from "babel-plugin-glsl/macro";

export default glsl`
  #pragma glslify: noise = require(glsl-noise/classic/4d);

  uniform float uTime;
  uniform float uAmount;
  uniform float uFrequency;
  varying vec2 vUv;
  varying vec3 vNormal;
  varying float vDistance;

  void main() {
    vec3 pos = position;
    float t = uTime * 1.0;
    float s = (sin(uTime) * 0.5) + 1.5;

    float perlin = noise(vec4(position * (uFrequency * s), t)) * uAmount;
    pos += normal * perlin;

    vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    float pointSize = 8.0;
    if (uAmount > 0.0) pointSize += (uAmount * 2.0);
    gl_Position = projectedPosition;
    gl_PointSize = pointSize * (pointSize / - viewPosition.z);

    vUv = uv;
    vNormal = normal;
    vDistance = distance(pos, vec3(0.0, 0.0, 1.0));
  }
`;
