import { AnimatePresence, motion } from "framer-motion"
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import Credit from "../../components/Credit";
import styles from "./styles.module.css";

const SelectionContext = createContext();

function useSelection(data) {
  const [selection, setSelection] = useState(null)
  const [selecting, setSelecting] = useState(false)

  const onMouseDown = index => {
    setSelecting(true)
    setSelection({ start: index, end: index })
  }

  const onMouseEnter = index => {
    if (!selecting) return;
    setSelection(existing => ({ ...existing, end: index }))
  }

  const selected = useMemo(() => {
    if (!selection) return []
    let start = selection.start < selection.end ? selection.start : selection.end;
    let end = selection.end > selection.start ? selection.end : selection.start;
    const items = data.slice(start, end + 1)
    return items.sort((a, b) => {
      const first = data.findIndex(i => i.id === a)
      const last = data.findIndex(i => i.id === b)
      return first - last
    })
  }, [data, selection])


  useEffect(() => {
    const handleMouseUp = () => {
      setSelecting(false)
    }

    document.addEventListener("mouseup", handleMouseUp)
    return () => document.removeEventListener("mouseup", handleMouseUp)
  }, [])

  return { data, selecting, selected, onMouseDown, onMouseEnter }
}

function Row({ item, nextItem, index }) {
  const { onMouseDown, onMouseEnter, selected } = useContext(SelectionContext)
  const isBeforeSelection = selected[0]?.id === nextItem?.id
  const isSelected = selected.some(i => i.id === item.id)

  return (
    <div id={item.id}
      data-before-selection={isBeforeSelection}
      data-bottom={selected[selected.length - 1]?.id === item.id}
      onMouseEnter={() => onMouseEnter(index)}
      className={styles.row} data-selected={isSelected}
    >
      <div className={styles.selector}>
        <motion.div
          animate={{ scale: isSelected ? [2, 1] : 1 }}
          className={styles.checkbox}
          onMouseDown={() => onMouseDown(index)}
          data-checked={isSelected}
        />
      </div>
      {item.title}
    </div>
  )
}

const ITEMS = [
  { id: 1, title: "Build list selection prototype" },
  { id: 2, title: "Respond to messages" },
  { id: 3, title: "Walk the dog" },
  { id: 4, title: "Code review " },
  { id: 5, title: "Send mailing list update" },
  { id: 6, title: "Do grocery shopping" },
  { id: 7, title: "Continue work on terminal game" },
  { id: 8, title: "Eat some chocolate" },
]

function Selection() {
  const { selected } = useContext(SelectionContext)
  const [selection, setSelection] = useState(null)

  useEffect(() => {
    if (selected.length > 0) {
      const first = document.getElementById(selected[0]?.id)
      const last = document.getElementById(selected[selected.length - 1]?.id)

      const firstRect = first?.getBoundingClientRect();
      const lastRect = last?.getBoundingClientRect();

      setSelection({
        opacity: selected.length > 0 ? 1 : 0,
        top: first?.offsetTop || 0,
        height: (lastRect?.bottom || 0) - (firstRect?.top || 0),
      })
    } else {
      setSelection(null)
    }

  }, [selected])

  return (
    <AnimatePresence>
      {selection && (
        <motion.div initial={selection} animate={selection} className={styles.selection} />
      )}
    </AnimatePresence>
  )
}

export default function Checkbox() {
  const state = useSelection(ITEMS);

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <SelectionContext.Provider value={state}>
          <div className={styles.list} data-selecting={state.selecting}>
            <Selection />
            {ITEMS.map((item, index) => {
              return <Row key={item.id} index={index} item={item} nextItem={ITEMS[index + 1]} />
            })}
          </div>
        </SelectionContext.Provider>
      </div>
      <Credit />
    </div>
  )
}
