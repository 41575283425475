import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import { useMemo, useRef } from "react";
import { OrbitControls } from "@react-three/drei";
import vertexShader from "./vertex";
import fragmentShader from "./fragment";
import { useControls } from "leva";
import Credit from "../../components/Credit";

function Light({ name, color, p, rotation, background }) {
  const mesh = useRef();
  const { uSpeed, colorA, size, length } = useControls(name, {
    colorA: { value: color },
    length: { value: 0.15, min: 0.05, max: 0.3, step: 0.01 },
    size: { value: 0.1, min: 0.1, max: 0.4, step: 0.01 },
    uSpeed: { value: 0.3, min: 0.1, max: 1.0, step: 0.01 },
  });

  const uniforms = useMemo(() => {
    return {
      u_time: {
        value: 0.0,
      },
      u_color: {
        value: new THREE.Color(colorA),
      },
      u_background: {
        value: new THREE.Color(background),
      },
      u_speed: {
        value: uSpeed,
      },
      u_length: {
        value: length,
      },
    };
  }, []);

  useFrame((state) => {
    const { clock } = state;
    mesh.current.material.uniforms.u_speed.value = uSpeed;
    mesh.current.material.uniforms.u_length.value = length;
    mesh.current.material.uniforms.u_time.value = clock.getElapsedTime();
    mesh.current.material.uniforms.u_color.value = new THREE.Color(colorA);
    mesh.current.material.uniforms.u_background.value = new THREE.Color(
      background
    );
    mesh.current.rotation.x += 0.005;
    mesh.current.rotation.y += 0.005;
    mesh.current.rotation.z += 0.005;
  });

  return (
    <>
      <mesh ref={mesh} rotation={rotation}>
        <torusKnotGeometry args={[3, size, 256, 64, p, 3]} />
        <shaderMaterial
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          uniforms={uniforms}
        />
      </mesh>
    </>
  );
}

function Scene() {
  const { background } = useControls({
    background: { value: "#48156c" },
  });

  return (
    <>
      <color args={[background]} attach="background" />
      <ambientLight />
      <OrbitControls />
      <Light background={background} name="Trail 1" color="#d289ff" p={2} />
      <Light
        background={background}
        rotation={[0.2, 0.3, 0.4]}
        name="Trail 2"
        color="#b1ffeb"
        p={3}
      />
      <Light
        background={background}
        rotation={[0.6, 0.4, 0.9]}
        name="Trail 3"
        color="#ffdd97"
        p={4}
      />
    </>
  );
}

export default function Example() {
  return (
    <>
      <Canvas camera={{ position: [0, 0, 10] }}>
        <Scene />
      </Canvas>
      <Credit />
    </>
  );
}
