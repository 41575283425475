import glsl from "babel-plugin-glsl/macro";

export default glsl`
  uniform float uTime;
  uniform vec3 uColor;
  uniform vec3 uColor2;
  uniform float uAmount;
  uniform vec3 uHighlight;
  varying vec2 vUv;
  varying float vDisplace;
  varying float vDistance;

  void main() {
    vec2 cxy = 2.0 * gl_PointCoord - 1.0;
    if (dot(cxy, cxy) > 1.0) discard;
    vec3 color = mix(uColor, uColor2, vDistance);
    gl_FragColor = vec4(color, 1.0);
  }
`;
