import * as THREE from "three";
import { extend } from "@react-three/fiber";

class MainPointsShader extends THREE.ShaderMaterial {
  constructor() {
    super({
      vertexShader: `
        uniform sampler2D uPositions;
        uniform float uFov;
        uniform float uTime;
        uniform float uBlur;
        uniform float uFocus;
        varying float vDistance;

        void main() {
          vec3 pos = texture2D(uPositions, position.xy).xyz;

          vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
          vec4 viewPosition = viewMatrix * modelPosition;
          vec4 projectedPosition = projectionMatrix * viewPosition;

          gl_Position = projectedPosition;

          vDistance = abs(uFocus - -viewPosition.z);
          
          gl_PointSize = (step(1.0 - (1.0 / uFov), position.x)) * vDistance * uBlur;
        }
      `,
      fragmentShader: `
        uniform vec3 uColor;
        uniform vec3 uHighlight;
        varying float vDistance;

        void main() {
          vec2 cxy = 2.0 * gl_PointCoord - 1.0;
          if (dot(cxy, cxy) > 1.0) discard;
          vec3 color = mix(uHighlight, uColor, vDistance);
          gl_FragColor = vec4(color, 1.0);
        }
      `,
      uniforms: {
        uPositions: { value: null },
        uTime: { value: 0 },
        uFocus: { value: 5.4 },
        uFov: { value: 50 },
        uBlur: { value: 30.0 },
        uColor: { value: new THREE.Color("#A700FF") },
        uHighlight: { value: new THREE.Color("#DE9EFF") },
      },
      // transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
    });
  }
}

extend({ MainPointsShader });
